






















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CampaignInfoPopup from '@campaigns/shared/components/CampaignInfoPopup.vue';
import { Campaign, CampaignTableRow, Nullable } from '@/app/shared/utilities/static-types';

@Component({
  components: {
    CampaignInfoPopup,
  },
})
export default class TableActions extends Vue {
  @Prop({ required: true, default: () => ({}) }) public campaignRow!: CampaignTableRow;
  @Prop({ required: true, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;

  public showCampaignMediaPopup(id: string): void {
    this.selectedCampaign = this.campaignRow;
    (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
  }
}
